import css from './Lobby.module.scss'
import Link from "next/link"
import {useRef} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Lobby = ({title, name, text, url, image}) => {

    //animation
    const pic = useRef(null)
    const {isVisible: inViewPic} = useVisible(pic)
    const desc = useRef(null)
    const {isVisible: inViewText} = useVisible(desc)
    //

    const img_data = SelectImage("/files/images/main/main-lobby-2.jpg", image);

    return (
        <section className={css.lobby}>
            <div className={inViewPic ? `${css.pic}` : `${css.pic} ${css.start}`} ref={pic}>
                <img
                  src={img_data}
                  alt={""}
                  width={'1920'}
                  height={'802'}
                  loading={'lazy'}
                />
            </div>
            <div className={inViewText ? `${css.text}` : `${css.text} ${css.start}`} ref={desc}>
                <h2 className='main-page__title main-page__lobby' dangerouslySetInnerHTML={{__html:title}}></h2>
                <div dangerouslySetInnerHTML={{__html:text}}></div>
                <Link href={`/lobby`}><a className={`${css.button} btn button--dark`}>{name}</a></Link>
            </div>
        </section>
    )
}

export default Lobby